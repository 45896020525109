@import "global";

.kuberg {
  $this: &;
  z-index: 3;
  margin-bottom: 60px;

  &__heading {
    //font-size: 90px;
  }

  &__letter {
    max-width: 420px;
    left: 71px;
  }

  &__copy {
    max-width: 500px;
  }

  .citrus {
    top: 0;
    right: -230px;
    background-color: rgba(#231f20, 0.1);
    opacity: 0;
    transform: scale(0);
  }

  .section__cta {
    background-color: #3c77b3;
    &:hover {
      background-color: darken(#3c77b3, 5);
    }

    .arrow path {
      fill: #589bdf;
    }

    &:after {
      background-color: #3c77b3;
    }

  }

}